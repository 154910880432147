<template>
  <div class="returntoresults_body">
    <div class="returntoresults_body_top d-flex align-items-center justify-content-between">
      <a href="javascript:void(0)" class="Closedetail_btn" @click="() =>$bvModal.show(`detailpuppyModal1-${_uid}`)">{{$t("sabre.buttons.more-detail")}}</a>
      <a href="javascript:void(0)" class="btn btn-outline-primary" @click="() => changeFlight()" v-if="isRoundTrip">{{$t("sabre.buttons.change-return-flight")}}</a>
    </div>
    <div class="returntoresults_body_bottom d-flex align-items-center justify-content-between">
      <price-box :totalPrice="totalPrice" propsClass="price"/>
      <div class="btnarea">
        <a href="#" class="btn btn-warning w-100" @click="() => orderFlight()">{{$t("sabre.buttons.order")}}</a>
      </div>
    </div>

    <b-modal
      :id="`detailpuppyModal1-${_uid}`"
      centered
      size="lg"
      :hide-footer="true"
      dialog-class="adjustbtnModal detailpuppyModal modal-dialog-centered"
    >
      <template #modal-header="{ close }">
        <button type="button" class="btn-close" data-bs-dismiss="modal" @click="() => close()"></button>
      </template>

      <collapse-flight-info :data="data" :kind="kind"/>

      <div class="flightdetails_footermodal">
        <div class="returntoresults_body_bottom d-flex align-items-center justify-content-between">

          <price-box :totalPrice="totalPrice" propsClass="price"/>

          <div class="btnarea">
            <a href="#" class="btn btn-warning w-100" @click="() => orderFlight()">{{$t("sabre.buttons.order")}}</a>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import { mapGetters } from 'vuex';

export default {
  name: 'mobile-one-price-item',
  props: {
    isRoundTrip: {
      type: Boolean,
      default: false,
    },
    totalPrice: {
      type: Number,
      default: 0,
    },
    data: {
      type: Object,
      default: null,
    },
    kind: {
      type: String,
      default: '',
    },
  },
  components: {
    BModal,
    CollapseFlightInfo: () => import('../../searchResultBox/searchResultBoxAtom/collapseFlightInfo'),
    PriceBox: () => import('../../searchResultBox/searchResultBoxAtom/priceBox'),
  },
  computed: {
    ...mapGetters({
      currentPage: 'GET_CURRENT_PAGE',
      searchQuery: 'GET_SABRE_FLIGHT_SEARCH_QUERY',
    }),
    isChgFltPg() {
      return this.currentPage === 'sabre-change-flight';
    },
  },
  mounted() {
    // console.log(this);
  },
  methods: {
    orderFlight() {
      this.$store.dispatch('SABRE_ORDER_FLIGHT', { flightId: this.data.id });
      this.$router.push({ path: '/sabre/fo-booking-passenger' });
    },
    changeFlight() {
      this.$store.dispatch('SABRE_CHANGE_FLIGHT', { flightId: this.data.id });
      this.$router.push({ path: `/sabre/fo-change-flights/?${this.searchQuery}&changeFlightID=${this.data.id}` });
    },
  },
};
</script>
